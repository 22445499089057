<template>
  <div class="home" :style="backImg">
    <img src="@/assets/01.jpg" alt="" />
    <div class="top">
      <img src="@/assets/01.png" alt="" />
    </div>
    <div>
      <div class="logo">
        <img src="@/assets/logo.png" alt="" />
      </div>
    </div>
    <div class="content">
      <!-- 一等奖 -->
      <div v-for="(item, index) in userValue" :key="index">
        <div class="title">{{ item.PrizeName }}</div>
        <div class="items">
          <div
            class="item"
            v-for="(element, index1) in item.data"
            :key="index1"
          >
            <img :src="element.photo" :alt="element.photo" />
            <div>{{ element.nickName }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 组件 -->
    <div style="position: absolute; top: 24%; left: 92%; z-index: 10">
      <div
        style="width: 50px; height: 50px; margin-bottom: 15px"
        @click="returnIndex"
      >
        <img src="@/assets/02.png" alt="" style="width: 100%; height: 100%" />
      </div>
      <!-- <Side /> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Side from "@/components/Side.vue";
import { Getresult } from "@/api/api.js";

export default {
  name: "Home",
  // components: { Side },

  data() {
    return {
      userValue: [
        {
          name: "一等奖",
          arr: [
            { img: "/img/头像.png", username: "奔跑的狮子" },
            { img: "/img/头像.png", username: "奔跑的狮子" },
            { img: "/img/头像.png", username: "奔跑的狮子" },
            { img: "/img/头像.png", username: "奔跑的狮子" },
            { img: "/img/头像.png", username: "奔跑的狮子" },
            // { img: "/img/头像.png", username: "奔跑的狮子" },
            // { img: "/img/头像.png", username: "奔跑的狮子" },
            // { img: "/img/头像.png", username: "奔跑的狮子" },
            // { img: "/img/头像.png", username: "奔跑的狮子" },
            // { img: "/img/头像.png", username: "奔跑的狮子" },
            // { img: "/img/头像.png", username: "奔跑的狮子" },
            // { img: "/img/头像.png", username: "奔跑的狮子" },
            // { img: "/img/头像.png", username: "奔跑的狮子" },
          ],
        },
        {
          name: "二等奖",
          arr: [
            { img: "/img/头像.png", username: "奔跑的狮子" },
            { img: "/img/头像.png", username: "奔跑的狮子" },
            { img: "/img/头像.png", username: "奔跑的狮子" },
            { img: "/img/头像.png", username: "奔跑的狮子" },
            { img: "/img/头像.png", username: "奔跑的狮子" },
          ],
        },
      ],
      backImg: "",
    };
  },
  created() {
    console.log(this.$route.query);
    let pass = {
      eventKey: this.$route.query.rewardname,
      // id: this.$route.query.rewardarr.id,
    };
    Getresult(pass).then((item) => {
      this.userValue = item.data;
      if (item.data.length == 0) {
        this.$message.error("你还没有抽奖呢");
        setTimeout(() => {
          this.$router.push({
            name: "Home",
          });
        }, 2000);
      }
    });
    this.backImg = localStorage.getItem("back");
  },
  methods: {
    returnIndex() {
      this.$router.push({
        name: "Home",
        query: {
          activityName: localStorage.getItem("activityName"),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  position: absolute;
  top: 8%;
  left: 10%;
  color: #fff;
}
.content {
  width: 69%;
  height: 59%;
  overflow-y: auto;
  position: absolute;
  top: 32%;
  left: 17%;
  .items {
    width: 98%;
    margin: 0% auto;
    height: 100%;
    // background: pink;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .item {
      width: 170px;
      height: 170px;
      margin: 40px 30px;
      border-radius: 50%;
      margin-bottom: 30px;
      background: url("../assets/backrou.png") no-repeat -26px -44px;
      img {
        width: 95%;
        height: 93%;
        margin-top: 6px;
        border-radius: 50%;
      }
      div {
        width: 80%;
        height: 42px;
        font-size: 23px;
        line-height: 42px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        /* line-height: 17px; */
        overflow: hidden;
        margin: auto;
        /* text-align: center; */
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.top {
  position: absolute;
  top: 23%;
  width: 90%;
  left: 7%;
  z-index: 10;
}
// 背景图片
.home {
  height: 100vh;
  overflow-y: hidden;
  // background: url("../assets/底背景.png");
  // background: url("https://lottery.mabotech.com/api/upload/viewImg?fileName=7cdc6ea5-9e5f-49df-9a1f-7bd4f895ba3c");
  // background-size: cover;
  // padding: 23px 20px;
}
.home > img {
  height: 76%;
  margin-top: 10%;
  width: 80%;
}
.title {
  width: 24%;
  height: 53px;
  font-size: 36px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffd800;
  line-height: 37px;
  /* margin-left: -18px; */
  margin: 0px auto;
  margin-top: 4%;
}
.content::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.content::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #fcd501;
}
.content::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ffffff;
}
</style>
