<template>
  <div class="home" :style="backImg">
    <img src="@/assets/01.jpg" alt="" />
    <div class="top">
      <img src="@/assets/01.png" alt="" />
    </div>
    <!-- 头部文字 -->
    <div>
      <div class="logo">
        <img src="@/assets/logo.png" alt="" />
      </div>
      <div
        style="
          position: absolute;
          top: 14%;
          right: 12%;
          font-weight: 900;
          font-size: 1.9rem;
          height: 37px;
          color: rgb(255, 255, 255);
          letter-spacing: 15px;
        "
      >
        参与人数:{{
          10 > NumberOfRaffles ? `0${NumberOfRaffles}` : `${NumberOfRaffles}`
        }}
      </div>
    </div>
    <!-- 抽奖画面 -->
    <div class="content">
      <!-- 轮播效果 -->
      <div class="block">
        <el-carousel
          height="75vh"
          :autoplay="false"
          indicator-position="none"
          :initial-index="userIndex"
        >
          <el-carousel-item v-for="(item, index) in fulllist" :key="item.id">
            <div style="position: absolute; width: 100%; top: 19%">
              <div class="content-txt">
                <div
                  style="
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #ffd800;
                    letter-spacing: 15px;
                    font-weight: 900;
                  "
                >
                  {{ item.prizeName }}
                </div>
              </div>
              <div class="content-img">
                <img
                  :src="
                    'https://lottery.mabotech.com/api/upload/viewImg?fileName=' +
                    item.prizeProductPhoto
                  "
                  alt=""
                />
              </div>
              <div class="prizeName"></div>
              <div
                style="position: fixed; bottom: 3%; z-index: 10; width: 100%"
              >
                <div style="display: flex; justify-content: center">
                  <img
                    @click="clickOPen(item, index)"
                    src="@/assets/抽  奖.png"
                    alt=""
                  />
                </div>
              </div>
              <!-- <div class="inop">
                一次抽取
                <input class="userValue" type="text" v-model="userValue" />人
              </div> -->
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="centerDialogVisible"
      width="30%"
      :show-close="false"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <div
        style="
          display: flex;
          flex-direction: row-reverse;
          flex-wrap: wrap;
          justify-content: space-around;
          font-size: 20px;
        "
      > <span>请勿重复抽奖！</span></div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="cliclshow">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import Side from "@/components/Side.vue";
import {
  Gettoken,
  GetActivityInformation,
  Getawardinformation,
  GetNumber,
} from "@/api/api.js";

export default {
  name: "Home",
  // components: { Side },
  data() {
    return {
      centerDialogVisible: false,
      userValue: "1",
      fulllist: "",
      rewardName: "安康有礼",
      Keywordvalue: "",
      backImg: "",
      activeMusic: "",
      userIndex: 0,
      NumberOfRaffles: "0",
      passItems: "",
    };
  },
  created() {
    // console.log(localStorage.getItem("index"));
    if (
      localStorage.getItem("activityName") != "" &&
      localStorage.getItem("activityName") != undefined &&
      localStorage.getItem("activityName") != "undefined"
    ) {
      this.rewardName = localStorage.getItem("activityName");
    } else {
      localStorage.setItem("activityName", this.rewardName);
    }
    if (
      localStorage.getItem("index") != "" &&
      localStorage.getItem("index") != undefined &&
      localStorage.getItem("index") != "undefined" &&
      localStorage.getItem("index") != "null"
    ) {
      if (
        this.$route.query.activityName ==
        localStorage.getItem("activityNameCode")
      ) {
        this.userIndex = parseInt(localStorage.getItem("index"));
      } else {
        this.userIndex = 0;
      }
    }
    if (
      this.$route.query.activityName != "" &&
      this.$route.query.activityName != "undefined" &&
      this.$route.query.activityName != undefined
    ) {
      localStorage.setItem("activityName", this.$route.query.activityName);
    }
    // console.log();
    // console.log(location.search);
    // 每次进页面就要获取一次token
    var obj = { eventKey: this.rewardName };
    Gettoken(obj).then((item) => {
      if (item.code == 200) {
        localStorage.setItem("token", item.token);
      } else {
        this.$message.error("获取token失败");
      }
      // 获取活动
      GetActivityInformation(obj).then((item) => {
        this.Keywordvalue = item.data;
        this.backImg = `background-image: url(https://lottery.mabotech.com/api/upload/viewImg?fileName=${item.data.activeBackgroud});background-size:cover;`;
        this.imgUrl = `https://lottery.mabotech.com/api/upload/viewImg?fileName=${item.data.activeBackgroud}`;
        if (item.code == 200) {
          localStorage.setItem("activeMusic", item.data.activeMusic);
          localStorage.setItem(
            "back",
            item.data.activeBackgroud
              ? `background-image: url(https://lottery.mabotech.com/api/upload/viewImg?fileName=${item.data.activeBackgroud});background-size:cover;`
              : "@/assets/01.jpg"
          );
          Getawardinformation({ eventId: item.data.id }).then((eleemnt) => {
            this.fulllist = eleemnt.data;
          });
        }
      });
    });
    // 进页面获取抽奖总人数
    this.automatic(obj);
    setInterval(() => {
      this.automatic(obj);
    }, 30000);
  },
  methods: {
    open() {
      this.$alert("请勿重复抽奖！", "", {
        confirmButtonText: "确定",
        center: true,
        width:"30%",
        callback: () => {
          // this.$message({
          //   type: "info",
          //   message: `action: ${action}`,
          // });
        },
      });
    },
    automatic(obj) {
      // console.log("开始获取了");
      GetNumber(obj).then((item) => {
        if (item.code == 200) {
          this.NumberOfRaffles = item.totalCount;
          // console.log(this.NumberOfRaffles > 10);
        } else {
          this.NumberOfRaffles = "总人数获取失败";
        }
      });
    },
    clickOPen(item, index) {
      this.passItems = item;

      if (item.beDrawn == 1) {
        // this.$message.error("该奖项已经抽取过了");
        this.centerDialogVisible = !this.centerDialogVisible;
        // this.open()
      } else {
        localStorage.setItem("index", index + 1);
        localStorage.setItem("activityNameCode", this.rewardName);
        this.passItems = item;
        this.$router.push({
          name: "About",
          query: {
            rewardValue: item, // 每次抽奖的数据
            SideValue: this.rewardName,
            Numberofwinners: this.fulllist,
            KeywordInformation: this.Keywordvalue,
            backimgUrl: this.imgUrl,
          },
        });
      }
    },
    cliclshow() {
      console.log(this.passItems);
      this.centerDialogVisible = !this.centerDialogVisible;
      // this.$router.push({
      //   name: "About",
      //   query: {
      //     rewardValue: this.passItems, // 每次抽奖的数据
      //     SideValue: this.rewardName,
      //     Numberofwinners: this.fulllist,
      //     KeywordInformation: this.Keywordvalue,
      //     backimgUrl: this.imgUrl,
      //   },
      // });
    },
    cliclhide() {
      this.centerDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  position: absolute;
  top: 23%;
  width: 90%;
  left: 7%;
  z-index: 10;
}
// 背景图片
.home {
  height: 100vh;
  overflow-y: hidden;
  // background: url("../assets/底背景.png") no-repeat 0px 0px center;
}
.home > img {
  width: 80%;
  height: 76%;
  margin-top: 10%;
}
// logo图片
.logo {
  position: absolute;
  top: 8%;
  left: 10%;
  color: #fff;
}
// 标题
.title {
  position: absolute;
  top: 0.5%;
  left: 7%;
  width: 86%;
  font-size: 1.5rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  // color: #ffd800;
}
// 抽奖画面块
.content {
  position: absolute;
  top: 24%;
  width: 94%;
  left: 3%;
  .content-txt {
    font-size: 36px;
    color: #fff;
  }
}
.content-img {
  // width: 200px;
  // height: 200px;
  width: 25vh;
  height: 25vh;
  margin: auto;
  img {
    width: 100%;
    height: 100%;
    margin-top: 50px;
  }
}
.prizeName {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fcd501;
  line-height: 72px;
  margin-bottom: 5%;
}
.userValue {
  width: 53px;
  height: 16px;
  background: #4a0766;
  border: 1px solid #935015;
}
.inop {
  // width: 145px;
  height: 15px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #633446;
  line-height: 72px;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.content .el-carousel__arrow::v-deep {
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  height: 36px;
  width: 36px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
  background-color: rgba(31, 45, 61, 0.11);
  color: #fff;
  position: absolute;
  top: 5% !important;
  z-index: 10;
  transform: translateY(-50%);
  text-align: center;
  font-size: 12px;
}

::v-deep .el-carousel__arrow {
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  height: 436px;
  width: 436px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
  background-color: rgba(31, 45, 61, 0.11);
  color: #fff;
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
  text-align: center;
  font-size: 12px;
}
::v-deep .el-carousel__arrow--left {
  left: -300px;
  top: 440px;
}
::v-deep .el-carousel__arrow--right {
  right: -300px;
  top: 440px;
}
::v-deep .el-dialog {
  margin-top: 50vh !important;
}
</style>
