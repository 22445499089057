<template>
  <div class="home" v-if="yemian">
    <div class="logo">
      <img src="@/assets/logo.png" alt="" />
      <!-- <div>主办单位：安徽康明斯</div> -->
    </div>

    <img :src="BackImg" alt="" />
    <div style="position: absolute; top: 9%; width: 100%">
      <div class="Userall">
        <div id="container"></div>
      </div>
      <!-- <div style="height: 100px"></div> -->
      <div
        style="
          display: flex;
          justify-content: center;
          z-index: 10;
          margin-top: 5%;
          align-items: center;
        "
      >
        <!-- <img src="" alt=""> -->
        <img src="@/assets/010.png" alt="" @click="clear" />
      </div>
    </div>
    <el-dialog
      title=" 中奖名单"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
      :show-close="false"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <div
        style="
          display: flex;
          flex-direction: row-reverse;
          flex-wrap: wrap;
          justify-content: space-around;
        "
      >
        <!-- <img src="" alt=""> -->
        <div
          class="items"
          v-for="item in passvalue.arr"
          :key="item.id"
          style="margin: 0px 10px; width: 15%; height: 15%"
        >
          <img
            style="
              border-radius: 50%;
              margin: 10px 0px;
              width: 100%;
              height: 100%;
            "
            :src="item.photo"
            alt=""
          />
          <div
            style="
              font-weight: 900;
              font-size: 20px;
              width: 130px;
              height: 37px;
              overflow: hidden;
              margin: auto;
              line-height: 37px;
              color: black;
            "
          >
            {{ item.participatorName }}
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="cliclshow">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import Side from "@/components/Side.vue";
// import OPen from "./AwardAnnouncement.vue";

import { GetNumber, addresult } from "@/api/api.js";
import * as THREE from "three";
import TWEEN from "@tweenjs/tween.js";
import { CSS3DRenderer, CSS3DObject } from "../js/CSS3DRenderer.js";
// import { TrackballControls } from "three/examples/jsm/controls/TrackballControls.js";
import { TrackballControls } from "../js/TrackballControls.js";
var camera, scene, renderer;
var objects = [];
var targets = { helix: [], table: [] };
export default {
  // components: { OPen },
  name: "About",
  data() {
    return {
      yemian: false,
      openShow: false,
      thetaAdd: 0.01, //频率递增值
      theta: 0, //初始频率
      open: "opacity:1",
      opvalue: true,
      clrea: "",
      dialogVisible: false,
      user: [],
      indexd: 0,
      chou: "",
      BackImg: "",
      table: [],
      WebGLoutpu: "",
      controls: "",
      passvalue: "",
      randomArr: [], //随机数组
      strandArr: [], //标准数组 校验
      blankImg: [], // 空白填充数据
      // 动态图库
      dynamic: [
        { img: "@/assets/blank0.jpg" },
        { img: "@/assets/blank1.jpg" },
        { img: "@/assets/blank2.jpg" },
        { img: "@/assets/blank3.jpg" },
        { img: "@/assets/blank4.jpg" },
        { img: "@/assets/blank5.jpg" },
        { img: "@/assets/blank6.jpg" },
      ],
      newarr: [],
    };
  },
  created() {
    window.THREE = THREE;
    TWEEN.removeAll();
    window.addEventListener("resize", this.onWindowResize, false);
    this.yemian = true;
    if (this.$route.query.SideValue != "") {
      GetNumber({ eventKey: this.$route.query.SideValue }).then((item) => {
        console.log();
        console.log();
        if (item.data.length != 0) {
          this.rotate3(item.data);
          this.table = item.data;
          if (item.data.length <= 100) {
            let subtraction = 100 - item.data.length;
            for (let index = 0; index < subtraction; index++) {
              let newarr = {
                photo: require(`@/assets/blank6.jpg`),
                nickName: "",
              };
              this.blankImg.push(newarr);
            }
            this.table.push(...this.blankImg);
          }
          this.shuffle(this.table);
          this.init();
          this.animation();
        }
      });
      this.clrea = setInterval(() => {
        this.opvalue = !this.opvalue;
        setInterval(this.getImg(), 500);
      }, 500);
    } else {
      this.$message.error("未获取到内容");
      setTimeout(() => {
        this.$router.push({
          name: "Home",
        });
      }, 2000);
    }
    // setTimeout(() => {
    //   this.thetaAdd = 0.02;
    // }, 4000);
    this.BackImg = this.$route.query.backimgUrl;
  },
  destroyed() {
    // try {
    // scene = []
    this.table = [];
    console.log(renderer);
    // renderer.dispose();
    // renderer.forceContextLoss();
    renderer.content = null;
    cancelAnimationFrame(this.animation); // 去除animationFrame
    // let gl = renderer.domElement.getContext("webgl");
    // gl && gl.getExtension("WEBGL_lose_context").loseContext();
    console.log("离开了");
    console.log(this.table);

    setTimeout(() => {}, 2000);
  },
  mounted() {},
  methods: {
    // 两个数组打乱顺序
    shuffle(arr) {
      var length = arr.length,
        randomIndex,
        temp;
      while (length) {
        randomIndex = Math.floor(Math.random() * length--);
        temp = arr[randomIndex];
        arr[randomIndex] = arr[length];
        arr[length] = temp;
      }
      return arr;
    },
    // 图片闪烁效果
    getImg() {
      if (this.opvalue) {
        //图片隐藏
        this.open = "opacity:0";
        // this.opvalue = "1";
      } else {
        this.open = "opacity:1";
        // this.opvalue = "0";
      }
    },
    rotate3(element) {
      element.filter((item) => {
        if (item.attended != 1) {
          this.user.push(item);
        }
      });
      console.log(this.user);

      // }, 100);
    },
    // 停止抽奖,公布名单
    clear() {
      console.log(this.$route.query);
      if (this.$route.query.rewardValue.people < this.user.length + 1) {
        this.user.forEach((item) => {
          item.eventKey = this.$route.query.SideValue;
          item.eventId = this.$route.query.rewardValue.eventId;
          item.participatorId = item.id;
          item.prizeId = this.$route.query.rewardValue.id;
          item.sponsorId = this.$route.query.KeywordInformation.sponsorsId; //主办单位ID
          item.participatorName = item.nickName;
          item.sponsorFullName =
            this.$route.query.KeywordInformation.sponsorsFullName; //主办单位名
          item.eventFullName = this.$route.query.rewardValue.eventFullName; //活动名
          item.prizeName = this.$route.query.rewardValue.prizeName; //奖项名
          item.prizeProductName =
            this.$route.query.rewardValue.prizeProductName; //奖品名
          item.prizeProductId = this.$route.query.rewardValue.prizeProductId; //奖品id
        });

        if (
          this.$route.query.rewardValue.people >
          parseInt(this.$route.query.rewardValue.beDrawnPeople)
        ) {
          this.newarr = this.fun(
            this.user,
            this.$route.query.rewardValue.people -
              parseInt(this.$route.query.rewardValue.beDrawnPeople)
          );
          this.passvalue = {
            reward: this.$route.query.SideValue,
            rewardarr: this.$route.query.rewardValue,
            prizeProductPhoto: this.$route.query.backimgUrl,
            arr: this.newarr,
          };
          addresult(this.passvalue.arr).then((item) => {
            if (item.code == 200) {
              this.open = "opacity:1";
              clearInterval(this.clrea);
              clearInterval(this.chou);
              this.thetaAdd = 0;
              this.openShow = true;
              this.dialogVisible = true;
              console.log(this.passvalue.arr);
            } else {
              this.$message.error("抽奖失败");
              setTimeout(() => {
                this.$router.push({
                  name: "Home",
                  query: {
                    activityName: localStorage.getItem("activityName"),
                  },
                });
                // location.reload();
                let div = document.getElementById("container");
                localStorage.setItem(
                  "index",
                  localStorage.getItem("index") - 1
                );

                div.remove();
                this.yemian = false;
              }, 3000);
            }
          });
        } else {
          this.$message.error(
            "该奖项中奖人数已经到达上限,要继续抽奖请在后台添加人数"
          );
          // localStorage.setItem("index", localStorage.getItem("index") - 1);

          setTimeout(() => {
            this.$router.push({
              name: "Home",
              query: {
                activityName: localStorage.getItem("activityName"),
              },
            });
            // location.reload();
            let div = document.getElementById("container");
            div.remove();
            this.yemian = false;
          }, 3000);
        }
      } else {
        this.$message.error("抽奖失败,人数不够");
        localStorage.setItem("index", localStorage.getItem("index") - 1);

        setTimeout(() => {
          this.$router.push({
            name: "Home",
            query: {
              activityName: localStorage.getItem("activityName"),
            },
          });
          // location.reload();
          let div = document.getElementById("container");
          div.remove();
          this.yemian = false;
        }, 3000);
      }
    },
    cliclshow() {
      // this.$router.go(-1);
      // console.log(this.passvalue.arr);

      this.$router.push({
        name: "AwardUser",
        query: {
          reward: this.$route.query.SideValue,
          rewardarr: this.$route.query.rewardValue,
          arr: this.passvalue.arr,
        },
      });
      this.yemian = false;
    },
    fun(arr, num = 1) {
      for (let i = 0; i < num; ) {
        const random = Math.floor(Math.random() * arr.length);
        if (arr[random].id != "") {
          if (this.newarr.indexOf(arr[random]) !== -1) {
            continue;
          } else {
            console.log();
          }
          this.newarr.push(arr[random]);
          i++;
        }
      }
      return this.newarr;
    },
    init() {
      camera = new THREE.PerspectiveCamera(
        40,
        window.innerWidth / window.innerHeight,
        1,
        10000
      );
      camera.position.x = 3600;
      scene = new THREE.Scene();
      // scene.translateX(200);

      //表格
      // console.log(this.table.length);
      for (var i = 0; i < this.table.length; i++) {
        var element = document.createElement("div");
        element.className = "element";
        // element.id = this.table[i].id;
        // element.style.backgroundColor =
        //   "rgba(119, 196, 161," + (Math.random() * 0.5 + 0.15) + ")";
        // element.style.border = "1px solid red";
        element.style.width = "300px";
        element.style.height = "300px";
        // element.style.margin = "10px;";

        // element.style.border = "1px solid rgba( 127, 255, 255, 0.25 )";
        // element.style.backgroundColor = 'rgba(119, 196, 161,0.3)'; border: 1px solid rgba( 127, 255, 255, 0.25 );
        var number = document.createElement("div");
        number.className = "number";
        number.textContent = i + 1;
        var image = document.createElement("img");
        image.className = "image-info";
        image.style.width = "40%";
        image.style.height = "40%";
        // image.style.paddingTop = "20px";
        image.src = this.table[i].photo;
        // console.log(this.table[i].photo);
        element.appendChild(image);
        var details = document.createElement("div");
        details.className = "details";
        details.innerHTML = this.table[i].nickName;
        details.style.color = "#fff";
        element.appendChild(details);
        // var partment = document.createElement("div");
        // partment.className = "partment";
        // partment.style.color = "#fff";
        // partment.innerHTML = this.table[i].department;
        // element.appendChild(partment);
        // console.log(element);
        var object = new CSS3DObject(element);
        // console.log(object);
        object.position.x = Math.random() * 4000 - 2000;
        object.position.y = Math.random() * 4000 - 2000;
        object.position.z = Math.random() * 4000 - 2000;
        scene.add(object);
        objects.push(object);
        //
        object = new THREE.Object3D();
        object.position.x = this.table[i].positionX * 140 - 2330; //模型平移
        object.position.y = -this.table[i].positionY * 180 + 990;
        targets.table.push(object);
      }

      var vector = new THREE.Vector3();
      // eslint-disable-next-line no-redeclare
      for (var i = 0, l = objects.length; i < l; i++) {
        this.theta = i * 0.175 + Math.PI;
        var y = -(i * 8) + 450;
        object = new THREE.Object3D();
        object.scale.set(9, 9, 7);
        // console.log(object.scale);

        object.position.setFromCylindricalCoords(900, this.theta, y);
        vector.x = object.position.x * 2;
        vector.y = object.position.y;
        vector.z = object.position.z * 2;
        object.lookAt(vector);
        targets.helix.push(object);
      }
      // 网格
      //实例
      renderer = new CSS3DRenderer();
      renderer.setSize(window.innerWidth, window.innerHeight - 200);
      document.getElementById("container").appendChild(renderer.domElement);

      //
      this.controls = new TrackballControls(camera, renderer.domElement);
      this.controls.minDistance = 500;
      this.controls.maxDistance = 6000;

      //监听

      this.transform(targets.helix, 3000); //初始化 图型
      window.addEventListener("resize", this.onWindowResize, false);
    },
    render() {
      // setTimeout(() => {
      renderer.render(scene, camera);
      // }, 1000);
    },
    animation() {
      this.theta += this.thetaAdd;
      // console.log(this.theta);
      scene.rotation.y = this.theta;
      renderer.render(scene, camera);
      requestAnimationFrame(this.animation);
      TWEEN.update();

      this.controls.update();
      this.controls.rotateCamera();
    },
    onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight - 100);
      this.render();
    },
    transform(targets, duration) {
      // console.log(targets);
      TWEEN.removeAll();
      for (var i = 0; i < objects.length; i++) {
        var object = objects[i];
        var target = targets[i];
        new TWEEN.Tween(object.position)
          .to(
            {
              x: target.position.x,
              y: target.position.y,
              z: target.position.z,
            },
            Math.random() * duration + duration
          )
          .easing(TWEEN.Easing.Exponential.InOut)
          .start();
        new TWEEN.Tween(object.rotation)
          .to(
            {
              x: target.rotation.x,
              y: target.rotation.y,
              z: target.rotation.z,
            },
            Math.random() * duration + duration
          )
          .easing(TWEEN.Easing.Exponential.InOut)
          .start();
      }
      new TWEEN.Tween(this)
        .to({}, duration * 2)
        .onUpdate(this.render)
        .start();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        // eslint-disable-next-line no-unused-vars
        .then((_) => {
          done();
        })
        // eslint-disable-next-line no-unused-vars
        .catch((_) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
@-webkit-keyframes rotate5 {
  0% {
    width: 60px;
  }
  // 20% {
  //   -webkit-transform: rotate(9deg);
  // }
  40% {
  }
  60% {
    -webkit-transform: rotate(-9deg);
  }
  80% {
    -webkit-transform: rotate(9deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
  // 0% {

  // }

  // 100% {
  // }
}

.items {
  animation: rotate5 2s ease-in-out;
}
.backOPen {
  width: 100%;
  height: 100%;
  // background: red;
  position: absolute;
  z-index: 1010;
  top: -2%;
}
.home {
  height: 100vh;
  overflow-y: hidden;
}

#show {
  border: 1px solid #fff;
  width: 1000px;
  background: red;
}

.home > img {
  width: 100%;
  height: 100vh;
}

.Userall {
  width: 79%;
  height: 60vh;
  overflow: hidden;
  margin: 0px auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.element1 {
  width: 100px;
  height: 30%;
  cursor: default;
  text-align: center;
  border: 1px solid rgba(127, 255, 255, 0.25);
  box-shadow: 0 0 12px rgba(0, 255, 255, 0.5);
}

.element:hover {
  border: 1px solid rgba(127, 255, 255, 0.75);
  box-shadow: 0 0 12px rgba(0, 255, 255, 0.75);
}
.element .number {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 12px;
  color: rgba(127, 255, 255, 0.75);
}
#container > div {
  height: 900px;
}
.symbol {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  font-size: 27px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.75);
  text-shadow: 0 0 10px rgb(0 255 255 / 95%);
}

.element .detail {
  width: 100%;
  height: 75%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  font-size: 12px;
  color: rgba(127, 255, 255, 0.75);
}

.image-info {
  width: 20px;
}
.logo {
  position: absolute;
  top: 8%;
  left: 10%;
  color: #fff;
}
#container {
  width: 79%;
  height: 590px;
  margin-top: -18%;
  margin-left: -13%;
}
::v-deep .el-dialog {
  position: relative;
  margin: 0 auto 50px;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  background: rgb(230 223 226 / 0.6);
  width: 50%;
}

::v-deep .el-dialog__title {
  line-height: 24px;
  font-size: 27px;
  font-weight: bolder;
  // color: #f2ab5c;
}
</style>
