import { get, post } from "./http";

// 获取活动信息
export const GetActivityInformation = (p) =>
  post("/api/eventManagement/getEventByKey", p);

// 获取奖项信息
export const Getawardinformation = (p) => post("/api/prizeManagement/getPrizes", p);

// 获取参与抽奖人数信息（传入活动no或者活动关键字）
export const GetNumber = (p) =>
  post("/api/participatorManagement/getParticipator", p);

// 新增抽奖结果信息
export const addresult = (p) => post("/api/lotteryResultManagement/addBatch", p);

// 获取抽奖结果信息
export const Getresult = (p) =>
  post("/api/lotteryResultManagement/getResultsByEventKey ", p);

// 获取抽奖结果信息
export const Getresultinformation = (p) =>
  post("/lotteryResultManagement/getDataByEventNo", p);

// 显示背景音乐
export const Showmusic = (p) => get("/upload/viewVido", p);

// 显示背景图片
export const ShowImg = (p) => get("/api/upload/viewImg", p);

// 获取/user/getTokenByEventKey
export const Gettoken = (p) => post("/api/user/getTokenByEventKey", p);

