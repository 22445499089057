import axios from "axios"; // 引入axios
// import QS from "qs"; // 引入qs模块，用来序列化post类型的数据，后面会提到
// 超时时间
axios.defaults.timeout = 10 * 1000;
// 跨域请求允许保存cookie
axios.defaults.withCredentials = true;
export function get(url, params) {
  console.log(params);
  return new Promise((resolve, reject) => {
    axios
      .get("https://lottery.mabotech.com" + url, {
        params
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export function post(url, params) {
  // console.log(QS.stringify(params));
  return new Promise((resolve, reject) => {
    axios
      .post("http://lotteryweb.mabotech.com" + url, params, {
        // .post("http://localhost:8080" + url, params, {
        headers: {
          accessToken: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}