<template>
  <div class="home" :style="BackImg">
    <img src="@/assets/01.jpg" alt="" />
    <div class="logo">
      <img src="@/assets/logo.png" alt="" />
      <!-- <div>主办单位：安徽康明斯</div> -->
    </div>
    <!-- 对应的奖品 -->
    <!-- <div
      style="
        width: 200px;
        height: 20vh;
        margin: auto;
        position: relative;
        top: -86%;
      "
    >
      <img style="width: 100%; height: 100%" :src="imgUrl" alt="" />
    </div> -->
    <div class="content" style="position: absolute">
      <div class="title">
        中 奖 名 单
        <!-- :{{ $route.query.rewardarr.prizeProductName }} -->
      </div>
      <!-- 中奖名单头像 -->
      <div class="items">
        <!-- 第一个 -->
        <div class="bj" v-for="item in list" :key="item.id">
          <img :src="item.photo" alt="" />
          <div>{{ item.nickName }}</div>
        </div>
      </div>
      <!-- 返回按钮 -->
      <div
        style="
          display: flex;
          justify-content: center;
          /* bottom: -24%; */
          /* position: absolute; */
          width: 18%;
          height: 19%;
          margin: auto;
        "
      >
        <!-- margin-left: 15px; -->
        <img @click="Return()" src="@/assets/06.png" alt="" />
        <!-- <div
          @click="Return()"
          style="position: absolute; width: 6%; margin-top: 20px"
        >
          返回按钮
        </div> -->
      </div>
      <!-- 侧边按钮 -->
      <!-- <div style="position: absolute; top: 15%; left: 120%">
        <Side />
      </div> -->
    </div>
  </div>
</template>
<script>
// import Side from "@/components/Side.vue";
// import { Getresult } from "@/api/api.js";

export default {
  // components: { Side },
  props: ["passValue"],

  name: "Home",
  data() {
    return {
      list: "",
      imgUrl: "",
      BackImg: "",
    };
  },
  created() {
    console.log(this.$route.query);
    this.imgUrl =
      "https://lottery.mabotech.com/api/upload/viewImg?fileName=" +
      this.$route.query.rewardarr.prizeProductPhoto;
    this.BackImg = localStorage.getItem("back");
    // console.log(this.BackImg);
    // let pass = {
    //   eventKey: this.$route.query.reward,
    //   id: this.$route.query.rewardarr.id,
    // };
    // Getresult(pass).then((item) => {
    this.list = this.$route.query.arr;
    console.log(this.list);
    // });

    // console.log(this.list);
    // this.list = this.passValue.arr;
  },
  methods: {
    Return(item) {
      console.log(item);
      this.$router.push({
        name: "Home",
        query: {
          activityName: localStorage.getItem("activityName"),
        },
      });
      location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
// logo图片
.logo {
  position: absolute;
  top: 8%;
  left: 10%;
  color: #fff;
}
.home {
  height: 100vh;
  overflow-y: hidden;
  // background: url("../assets/底背景.png") no-repeat 0px 0px;
  // background-size: cover;
}
.home > img {
  width: 80%;
  height: 76%;
  margin-top: 10%;
}
.content {
  position: absolute;
  left: 21.6%;
  width: 60%;
  top: 24%;
  height: 62%;
}
.title {
  font-size: 33px;
  // color: #fff;
  position: absolute;
  top: 14%;
  left: 0%;
  width: 100%;
  font-family: Microsoft YaHei;
  font-weight: 900;
  color: #fcd501;
}
.sideName {
  font-size: 24px;
  font-family: "Microsoft YaHei";
  font-weight: 400;
  color: rgb(17, 229, 239);
  line-height: 43px;
}
.bj {
  // width: 209px;
  // height: 209px;
  // border-radius: 50%;
  // margin-bottom: 30px;
  // margin: 13px 30px;
  // margin-bottom: 30px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 13px 30px;
  margin-bottom: 5%;
  background: url("../assets/backrou.png") no-repeat -26px -55px;
  margin-top: 3px;
  margin-left: 2px;

  img {
    border-radius: 50%;
    width: 97%;
    height: 97%;
    z-index: 10;
    margin-top: 3px;
  }
  div {
    width: 100%;
    height: 17px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 17px;
    width: 62%;
    height: 42px;
    font-size: 23px;
    line-height: 42px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    /* line-height: 17px; */
    overflow: hidden;
    margin: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.items {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 15%;
  // margin-top: 9%;
  height: 68%;
  flex-wrap: wrap;
  overflow-y: auto;
  // overflow: hidden;
}
.items::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.items::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #fcd501;
}
.items::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ffffff;
}
</style>
